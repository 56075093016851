























































































import { mixins } from "vue-class-component";
import { Component } from "vue-property-decorator";
import AnswerMultiple from "@/views/Student/LearnStatus/PreExamsList/AnswerMultiple.vue";
import AnswerFree from "@/views/Student/LearnStatus/PreExamsList/AnswerFree.vue";
import AnswerMultipleWithMedia from "@/views/Student/LearnStatus/PreExamsList/AnswerMultipleWithMedia.vue";
import FscModal from "@/components/Modal/FscModal.vue";

@Component({
  components: { AnswerMultipleWithMedia, FscModal, AnswerMultiple, AnswerFree },
})
export default class Question extends mixins() {
  public name = "Question.vue";

  public question: any = null;

  public isMultipleChoiceType: any = null;
  public isFreeTextType: any = null;
  public isMultipleChoiceWithMediaType: any = null;

  public setSelected(question: any) {
    this.isMultipleChoiceType = this.isMultipleChoice(question);
    this.isFreeTextType = this.isFreeText(question);
    this.isMultipleChoiceWithMediaType = this.isMultipleChoiceWithMedia(question);
    this.question = question;
  }

  public isMultipleChoice(question: any) {
    return question != null && question.questionType === "MULTIPLE_CHOICE";
  }

  public isFreeText(question: any) {
    return question != null && question.questionType === "FREE_TEXT";
  }

  public isMultipleChoiceWithMedia(question: any) {
    return question != null && question.questionType === "MULTIPLE_CHOICE_WITH_MEDIA";
  }

  private get isMediaVideo(): boolean {
    return this.question.withVideo;
  }

  private get isMediaPicture(): boolean {
    return this.question.withPicture;
  }

  private get isMediaQuestion(): boolean {
    return this.isMediaVideo || this.question.withPicture;
  }

  public thumbnail: any = "START";
  public questionVideoId: any = "question-video-id-lms";
  public questionPictureId: any = "question-picture-id-lms";

  public get mediaUrl(): string {
    if (this.question.withPicture) {
      return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.question.id}/media`;
    }
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.question.id}/media?thumbnail=${this.thumbnail}`;
  }

  private showVideo(): void {
    this.$bvModal.show(this.questionVideoId);
  }

  private showInModalIfPicture(): void {
    if (this.isMediaPicture) {
      this.$bvModal.show(this.questionPictureId);
    }
  }

  private onCloseVideo() {
    this.thumbnail = "END";
  }

  public get videoUrl(): string {
    return `${process.env.VUE_APP_BACKEND_BASE_URL}/theory-questions/${this.question.id}/media`;
  }

  public onClose() {
    this.$emit("on-close");
  }

  public setDefaultImage(event: any) {
    event.target.src = require("@/assets/lms-drivebuzz.png");
  }
}
